import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/vphe-8.jpeg"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="VPHE"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center py-3">
                VPHE
                </h1>
                {/* Текст */}
                <p class="blockquote card px-4 mx-5 py-3">Сеялки для точного высева серия Ozdoken VPHE производятся с анкерными сошниками. Сеялки для
                    точного высева серии Ozdoken VPHE-D производятся с дисковыми сошниками. Расстояния между рядами
                    можно выбирать на желаемом расстоянии. Возможно посеять кукурузу, подсолнечник, сахарную свеклу, сою
                    и прочие пропашные культуры. Система внесения удобрений и микрогранулятор доступны дополнительно.
                    Сеялки производятся от 2-х рядов до 12-ти рядов, 16 рядов с использованием сцепки.
                </p>

                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>

                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="ДИСКОВЫЙ ТИП СЕКЦИЙ" />
                    <Prodfeat text="АНКЕРНЫЙ ТИП СЕКЦИЙ" />

                    <Prodfeat text="Пластический Бункер Для Удобрений" />
                    {/* <Prodfeat text="Односторонний Гидравлические Маркеры" /> */}
                    <Prodfeat text="Двойной Гидравлический Маркер" />
                    <Prodfeat text="Анкерный Сошник Для Внесения Удобрений" />
                    <Prodfeat text="2 Набор Высевающие Диски" />
                    {/* <Prodfeat text="Вал Pto (Может Быть Выбран Восьмиугольный Или Шестиугольный Вход)" /> */}
                    <Prodfeat text="Счетчик Площади" />

                </div>

                {/* Преимущества */}
                <h2 class="text-center pt-3 text-black-50">
                    ОБОРУДОВАНИЕ ПРОДУКТА
                </h2>
                <div class="row px-3">
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257552011478516-75-----1920-1080.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕКЦИИ С ДВУХДИСКОВЫМ СОШНИКОМ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">СЕКЦИИ С ДВУХДИСКОВЫМ СОШНИКОМ</strong><p class="h5"></p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637423388163425011-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. СЕКЦИЯ С АНКЕРНЫМ СОШНИКОМ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">СЕКЦИЯ С АНКЕРНЫМ СОШНИКОМ</strong><p class="h5"></p> </div> </div> </div>



                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257411239938832-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. БУНКЕР ДЛЯ ЗЕРНА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">БУНКЕР ДЛЯ ЗЕРНА</strong><p class="h5">40 л Высокорезистивный Пластический Бункер</p> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257608059362417-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ПРИВОД КАРДАННОГО ВАЛА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПРИВОД КАРДАННОГО ВАЛА</strong><p class="h5">Карданный вал надежная и точная система движения и не требует технического обслуживания.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257416089355615-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ЗАКРЫТИЕ БОРОЗДЫ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ЗАКРЫТИЕ БОРОЗДЫ</strong><p class="h5">Стандартное Закрытие Борозды</p> </div> </div> </div>
                    {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257417318731839-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. СЕКЦИЯ ДЛЯ САХАРНОЙ СВЕКЛЫ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">СЕКЦИЯ ДЛЯ САХАРНОЙ СВЕКЛЫ</strong><p class="h5">Для мелких зерн, особенно сахарной свеклы, имеется переднее прикатывающее колесо для идеальной подготовки зерновой почвы.</p> </div> </div> </div> */}
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257418511075405-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ПО СТАНДАРТУ СЧЕТЧИК ПЛОЩАДИ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПО СТАНДАРТУ СЧЕТЧИК ПЛОЩАДИ</strong><p class="h5">Счетчик площади является стандартным для всех моделей.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257370202840020-b75---digitroll-dms08----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ДОПОЛНИТЕЛЬНЫЙ DIGITROLL DMS-08 КОНТРОЛЛЕР ВНЕСЕНИЯ СЕМЯН" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ДОПОЛНИТЕЛЬНЫЙ DIGITROLL DMS-08 КОНТРОЛЛЕР ВНЕСЕНИЯ СЕМЯН</strong><p class="h5">• Фотосервированный мониторинг семян до 8 секции • Общий и частичный гектар счетчик • Автоматическая диагностика системы • Звуковая и визуальная сигнализация блокировки • 6 выбираемых порогов для пропущенных семенных сигналов • Выбор размера семенного материала для обнаружения очищенных семян</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257423547879561-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ДВУХСТОРОННИЙ ГИДРАВЛИЧЕСКИЙ МАРКЕР СЕКЦИИ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ДВУХСТОРОННИЙ ГИДРАВЛИЧЕСКИЙ МАРКЕР СЕКЦИИ</strong><p class="h5">Двойной гидравлический маркер рядов хороший выбор, если на полях есть препятствия.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257410640261445-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ЛЕГКО ЗАМЕНЯЕМЫЙ ДВОЙНЫЕ СИНГУЛЯТОРЫ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ЛЕГКО ЗАМЕНЯЕМЫЙ ДВОЙНЫЕ СИНГУЛЯТОРЫ</strong><p class="h5">Для идеальной высевания всех виды зерно на всех рядковых единицы селекторы можно отрегулировать с наружной стороны распределителя с помощью мерной шкалы.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637256746666800074-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ПРИВОДНЫЕ КОЛЕСА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПРИВОДНЫЕ КОЛЕСА</strong><p class="h5">Стандартная система колес имеет неперерывный контакт на земле, что снижает нагрузку на рядковые единицы на земле.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257414463938749-b75--550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ВАКУУМНАЯ СИСТЕМА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ВАКУУМНАЯ СИСТЕМА</strong><p class="h5">Вакуумная система должна быть надежной во время работы. Наш вал PTO привода системы вентилятора на 540 об/мин, надежен даже в часы высоких работ, и бесшумен для удобства пользователя.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257415567627998-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ПАРАЛЛЕЛЬНОЕ РЕДАКТИРОВАНИЕ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ПАРАЛЛЕЛЬНОЕ РЕДАКТИРОВАНИЕ</strong><p class="h5">Параллельное редактирование установлено на сменных металлических втулках. регулируемая прижимная пружина стандартна.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257416652275255-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ЗАДНЕЕ ПРЕССОВОЕ КОЛЕСА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ЗАДНЕЕ ПРЕССОВОЕ КОЛЕСА</strong><p class="h5">500X175 Резиновое прикатывающее колесо является стандартной моделью на топорный тип рядковых единицы.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257417930253273-b75------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. АНКЕРНЫЙ СОШНИКИ ДЛЯ ВНЕСЕНИЯ УДОБРЕНИЙ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">АНКЕРНЫЙ СОШНИКИ ДЛЯ ВНЕСЕНИЯ УДОБРЕНИЙ</strong><p class="h5"></p> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark">
                        <StaticImage src="img/637257559611322333-75-------1920-1080.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} 
                        alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ДИСКОВЫЙ ТИП СОШНИКИ ДЛЯ ВНЕСЕНИЯ УДОБРЕНИЙ " style={{ marginBottom: `1.45rem` }} /><strong class="h4">
                           ДИСКОВЫЙ ТИП СОШНИКИ ДЛЯ ВНЕСЕНИЯ УДОБРЕНИЙ </strong><p class="h5"></p> </div> </div> </div>

                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257420641238556-b75---ts----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ДОПОЛНИТЕЛЬНЫЙ СЕРИЯ TS КОНТРОЛЛЕРА ВНЕСЕНИЯ СЕМЯН" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ДОПОЛНИТЕЛЬНЫЙ СЕРИЯ TS КОНТРОЛЛЕРА ВНЕСЕНИЯ СЕМЯН</strong><p class="h5">-Контроль высева - Визуальный И Звуковой Сигнал Тревоги - Скорость Высева - Счетчик Площади</p> </div> </div> </div>
                    {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637257422833532482-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. ОДНОСТОРОННИЙ ГИДРАВЛИЧЕСКИЙ МАРКЕР СЕКЦИИ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ОДНОСТОРОННИЙ ГИДРАВЛИЧЕСКИЙ МАРКЕР СЕКЦИИ</strong><p class="h5">По стандартной комплектации, Все модели оснащены с одним гидравлическим маркером ряда.</p> </div> </div> </div> */}
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637752627502096681-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="ЦЕЛЬНАЯ РАМА АНКЕРНЫЙ ТИП. КОРОБКА ПЕРЕДАЧ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">КОРОБКА ПЕРЕДАЧ</strong><p class="h5">С помощью механического редуктора легко настроить расстояние между семенами. Для точного высева коробка передач подсоединена к рядным единицам наилучшим и безопасным способом.</p> </div> </div> </div>

                </div>

                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">


                        <thead class="background-dark-gray-ozdo"><tr>
                        <th>МОДЕЛИ</th>
		<th>КОЛИЧЕСТВО СЕКЦИЙ</th>
		<th>РАССТОЯНИЯ МЕЖДУ РЯДАМИ<br />(cм)</th>
		<th>ТРАНСПОРТНАЯ ШИРИНА<br />(cм)</th>
		<th>МИНИМАЛЬНЫЙ ВЕС<br />(кг)</th>
		
		<th>ПЛАСТИКОВЫЙ БУНКЕР ДЛЯ УДОБРЕНИЙ<br />(л)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
		<td>VPHE-DG8</td>
		<td>8</td>
		<td>70</td>
		<td>560</td>
		<td>1890</td>
		
		<td>180 л x 4</td>
	</tr>
	<tr>
		<td>VPHE-D8</td>
		<td>8</td>
		<td>70</td>
		<td>560</td>
		<td>1485</td>
		
		<td>Без удобрений</td>
	</tr>
	<tr>
		<td>VPHE-G8</td>
		<td>8</td>
		<td>70</td>
		<td>560</td>
		<td>1450</td>
		
		<td>180 л x 4</td>
	</tr>
	<tr>
		<td>VPHE-8</td>
		<td>8</td>
		<td>70</td>
		<td>560</td>
		<td>1100</td>
		
		<td>Без  удобрений</td>
	</tr>
	<tr>
		<td>VPHE-DG12</td>
		<td>12</td>
		<td>45-50</td>
		<td>560</td>
		<td>2400</td>
		
		<td>180 л x 4</td>
	</tr>

                        </tbody>

                    </table>
                </div>
            </div>
        </Container>
    </Layout>
);

export default FDP;